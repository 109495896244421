import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Styles from './Footer.module.scss';
import {Col, Image} from "react-bootstrap";
import appStore from "../../store/AppStore";
import {observer} from "mobx-react";

@observer
class Footer extends Component {
    render() {

        return (
            <div className={Styles.Footer}>
                <div className={`d-flex flex-wrap justify-content-center w-100`}>
                    <Col md={4} sm={12} className={`pe-3`}>
                        <Image src={`/asset/img/footer_logo.svg`} />
                    </Col>
                    <Col md={8} sm={12} className={`d-flex align-items-center justify-content-end`}>
                        <div className={Styles.Links}>
                            <div className={Styles.Link}>
                                회사소개
                            </div>
                            <div className={Styles.Link}>
                                개인정보처리방침
                            </div>
                            <div className={Styles.Link}>
                                오시는 길
                            </div>
                        </div>
                    </Col>
                </div>
                <hr className={Styles.Line}/>
                <div className={`d-flex flex-wrap justify-content-between`}>
                    <Col md={4} sm={12}>
                        <div className={Styles.Title}>대량/기업고객 문의</div>
                        <div className={Styles.Tel}>032.572.5817</div>
                        <div className={Styles.Description}>
                            평일 10:00 ~ 19:00{`\n`}
                            점심 13:00 ~ 14:00{`\n`}
                            주말 및 공휴일은 휴무 입니다.
                        </div>
                    </Col>
                    <Col md={4} sm={12}>
                        <div className={Styles.Title}>일반 소비자 문의</div>
                        <div className={Styles.Tel}>010.4298.2102</div>
                        <div className={Styles.Description}>
                            평일 10:00 ~ 19:00{`\n`}
                            점심 13:00 ~ 14:00{`\n`}
                            주말 및 공휴일은 휴무 입니다.
                        </div>
                    </Col>
                    <Col md={4} sm={12}>
                        <div className={Styles.Title}>BRAND INFO</div>
                        <div className={Styles.Description}>
                            법인명(상호) : (주)형제싱크{`\n`}
                            대표자(성명) : 김윤  |  사업자 등록번호 안내 : [143-86-00413]{`\n`}
                            통신판매업 신고 2014-인천서구-0535{`\n`}
                            전화 : 032.572.5817  |  팩스 : 032.573.5817{`\n`}
                            주소 : 인천광역시 동구 방축로 177번길 36, b동 1층 우측(송림동){`\n`}
                            개인정보관리책임자 : 김윤
                        </div>
                    </Col>
                </div>
                <div className={Styles.Copy}>
                    Copyright © 2024 (주)형제싱크.  All rights reserved. Hosting by 200interior
                </div>
            </div>
        );
    }
}

Footer.propTypes = {};

export default Footer;