import React, {Component} from 'react';
import {Tab, Tabs} from "react-bootstrap";
import {observer} from "mobx-react";
import Styles from "./IconTab.module.scss";
import {iconTabStore} from "./IconTabStore";
import {navigate} from "../../helper/HistoryHelper";

@observer
class IconTab extends Component {


    render() {
        const {items, isActive = false, isLabel = true} = this.props;

        return (
            <>
                {
                    items && items.length > 0 &&
                    <div  className={Styles.Tabs}  >
                        {
                            items.map((item, index) => {
                                return (
                                    <div key={index} onClick={() => {
                                        document.getElementById("scroll").scrollTo({left: 0, top: 0, behavior: "instant"})
                                        iconTabStore.selectedTab = item.key;
                                        navigate(item.url);
                                        }}
                                        className={`${Styles.TabItemContainer} ${(iconTabStore.selectedTab === item.key || isActive) && Styles.Active}`}>
                                        {React.cloneElement(item.icon, {className: Styles.TabItem})}
                                        {
                                            isLabel && <div className={Styles.TabLabel}>{item.label}</div>
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                }
            </>
        );
    }
}

export {IconTab as default};