import React, {Component, useEffect, useState} from 'react';
import {Button, Image, Modal} from "react-bootstrap";
import {observer} from "mobx-react";
import {useGoogleLogin} from "@react-oauth/google";
import FacebookLogin from 'react-facebook-login';
import {TiSocialFacebookCircular} from "react-icons/ti";


const LoginDialog = observer(({store}) => {

    const login = useGoogleLogin({
        onSuccess: async tokenResponse => {
            const userInfo = await new Promise(resolve => {
                const xhr = new XMLHttpRequest();

                xhr.open('GET', `https://www.googleapis.com/oauth2/v3/userinfo`);
                xhr.setRequestHeader('Authorization', `Bearer ${tokenResponse.access_token}`)
                xhr.onload = function () {
                    if (this.status >= 200 && this.status < 300)
                        resolve(JSON.parse(this.responseText));
                    else resolve({ err: '404' });
                };
                xhr.send();
            });
            console.log(userInfo)
        },
        onError: e=>{
            console.log(e)
        }
    });


    return (
        <Modal show={store.show}
               onHide={()=>{
                    store.close()
               }}
        >
            <Modal.Body>
                <FacebookLogin
                    appId="2759883737366519"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={res=>console.log(res)}
                    cssClass="my-facebook-button-class"
                    icon={<TiSocialFacebookCircular />}
                />
                <div onClick={login}>
                    <Image src={`/asset/img/google_login.svg`}/>

                </div>
            </Modal.Body>
        </Modal>
    );

});

export {LoginDialog};
