import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import Styles from "./SideMenu.module.scss";
import {navigate} from "../../helper/HistoryHelper";
import appStore from "../../store/AppStore";
import {makeObservable, observable} from "mobx";
import {businessTabs} from "../tab/IconTabs";
import * as IconTabs from "../tab/IconTabs";
import {iconTabStore} from "../tab/IconTabStore";

const SubMenu = (props) => {
    const {items, isActive} = props;
    return (
        <div className={`${Styles.SubMenu} ${isActive ? Styles.On : Styles.Off}`}>
            {
                items.map((item, index) => (
                    <div key={index} onClick={() => {
                        navigate(item.url);
                        iconTabStore.selectedTab = item.key;
                        appStore.sideBarOpen = false;
                        appStore.selectedSideMenu = '';
                        document.getElementById("scroll").scrollTo({left: 0, top: 0, behavior: "instant"})
                    }}>
                        {item.label}
                    </div>
                ))
            }
        </div>
    )
}
@observer
class SideMenu extends Component {

    constructor(props) {
        super(props);
        makeObservable(this);
    }
    menuOpen(url){
        navigate(url)
        document.getElementById("scroll").scrollTo({left: 0, top: 0, behavior: "instant"})
        appStore.sideBarOpen = false;
        appStore.selectedSideMenu = '';
    }

    render() {
        let {headerColor} = this.props;
        return (


            <div className={Styles.SideMenu} style={{transform:`translateX(${appStore.sideBarOpen ? `0%` : `100%` })`}}>
                <div className={Styles.Menu}
                     onClick={()=>{
                         this.menuOpen('/company')
                     }}
                >
                    회사소개
                </div>
                <div  className={Styles.Menu}
                    onClick={() => {
                        if(appStore.selectedSideMenu === 'business') {
                            appStore.selectedSideMenu = '';
                        }else {
                            appStore.selectedSideMenu = 'business';
                        }
                    }}
                >
                    사업소개
                </div>
                <SubMenu items={IconTabs.businessTabs}
                         isActive={appStore.selectedSideMenu === 'business'}/>
                <div className={Styles.Menu}
                     onClick={() => {
                         if(appStore.selectedSideMenu === 'material') {
                             appStore.selectedSideMenu = '';
                         }else {
                             appStore.selectedSideMenu = 'material';
                         }
                     }}
                >
                    자재소개
                </div>
                <SubMenu items={IconTabs.materialIcons}
                         isActive={ appStore.selectedSideMenu === 'material'}/>
                <div className={Styles.Menu}
                     onClick={() => {
                         if(appStore.selectedSideMenu === 'hardware') {
                             appStore.selectedSideMenu = '';
                         }else {
                             appStore.selectedSideMenu = 'hardware';
                         }
                     }}
                >
                    하드웨어
                </div>
                <SubMenu items={IconTabs.hardwareIcons}
                         isActive={ appStore.selectedSideMenu === 'hardware'}/>
                <div className={Styles.Menu}
                     onClick={()=>{
                         appStore.isCustomerService = true;
                         this.menuOpen('/company');
                    }}
                >
                    고객센터
                </div>
            </div>

        );
    }
}

SideMenu.propTypes = {};

export default SideMenu;