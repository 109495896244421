import React, {Component} from 'react';
import {observer} from "mobx-react";
import {Col, Container, Image} from "react-bootstrap";
import Styles from "./MaterialView.module.scss";
import MaterialContent from "./MaterialViewContent.json";
import HorizonLine from "../component/Decoration/HorizonLine";
import IconTab from "../component/tab/IconTab";
import {iconTabStore} from "../component/tab/IconTabStore";
import ScrollFadeIn from "../component/animate/ScrollFadeIn";
import * as IconTabs from "../component/tab/IconTabs";
import TabHeader from "../component/tab/TabHeader";

const MaterialRender = (props) => {
    const {type} = props;
    return (
        <>
            {
                type  && IconTabs.materialIcons.map(icon => icon.key).includes(type) &&
                <>
                    <div className={Styles.HeaderContent}>
                        <div className={`flex-grow-1 p-0 d-flex flex-wrap`}>
                            <Col sm={12} md={6} className={`p-0 align-items-center`}>
                                <div className={Styles.Content}>
                                    <div className={`${Styles.LightTitle} `}>Style Upgrade !</div>
                                    <div className={`${Styles.LightTitleBold} `}>Material 자재소개
                                        / {MaterialContent[type].title}</div>
                                    <div className={`${Styles.Description} `}>(주)형제씽크의 대표 자재들을 소개합니다.</div>
                                </div>
                            </Col>
                            <Col sm={12} md={6} className={`p-0 d-flex justify-content-end align-items-center`}>
                                <Image className={Styles.Image}
                                       src={MaterialContent[type].imageUrl}/>
                            </Col>
                        </div>
                    </div>
                    <div className={Styles.Section}>
                        <Container className={Styles.Container}>
                            <div className={Styles.Tabs}>
                                <ScrollFadeIn
                                    direction={'up'}
                                    duration={0.5}
                                    delay={0.1}
                                >
                                    <IconTab
                                        items={IconTabs.materialIcons}
                                    />
                                </ScrollFadeIn>
                            </div>
                            <ScrollFadeIn
                                direction={'up'}
                                duration={0.5}
                                delay={0.1}
                            >
                                <div className={Styles.Content}>
                                    <div
                                        className={Styles.Title}>{MaterialContent[type].subject}</div>
                                    <div
                                        className={Styles.Description}>{MaterialContent[type].content}</div>
                                </div>
                            </ScrollFadeIn>
                            {
                                MaterialContent[type].data.map((content, index) => {
                                    return (
                                        <>
                                            <div className={Styles.Data}>
                                                <ScrollFadeIn
                                                    direction={'up'}
                                                    duration={0.5}
                                                    delay={0.1}
                                                >
                                                    <div className={Styles.TitleContainer}>
                                                        <div className={Styles.MainTitle}>{content.mainTitle}</div>
                                                        <div className={Styles.SubTitle}>{content.subTitle}</div>
                                                    </div>
                                                </ScrollFadeIn>
                                                <div className={Styles.ImageContainer}>
                                                    {
                                                        content.images?.map((image, index) => {
                                                            return (
                                                                <div key={index} className={Styles.Item}>
                                                                    <ScrollFadeIn
                                                                        direction={'up'}
                                                                        duration={1}
                                                                        delay={0.1}
                                                                    >
                                                                        <Image className={'w-100'} src={image.url}/>
                                                                        <div className={Styles.Label}>{image.label}</div>
                                                                    </ScrollFadeIn>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            {
                                                index < MaterialContent[type].data.length - 1 &&
                                                <HorizonLine color={"#6C757D"} stroke={"0.0625rem"}/>
                                            }
                                        </>
                                    )
                                })
                            }
                        </Container>
                    </div>
                </>
            }
        </>
    )
}
@observer
class MaterialView extends Component {
    componentDidMount() {
        let {match: {params}} = this.props;
        if(params && params.type){
            iconTabStore.selectedTab = params.type;
        }else{
            iconTabStore.selectedTab = IconTabs.materialIcons[0].key;
        }
    }

    render() {
        return (
            <div className={'d-flex flex-column align-items-center'}>
                <TabHeader items={IconTabs.materialIcons}/>
                {iconTabStore.selectedTab === 'door' && <MaterialRender type={iconTabStore.selectedTab} />}
                {iconTabStore.selectedTab === 'countertop' && <MaterialRender type={iconTabStore.selectedTab} />}
                {iconTabStore.selectedTab === 'handle' && <MaterialRender type={iconTabStore.selectedTab} />}
                {iconTabStore.selectedTab === 'hinge' && <MaterialRender type={iconTabStore.selectedTab} />}
            </div>
        );
    }
}

export default MaterialView;