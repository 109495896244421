import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Styles from './Header.module.scss';
import {Dropdown, Image} from "react-bootstrap";
import appStore from "../../store/AppStore";
import {observer} from "mobx-react";
import {navigate} from "../../helper/HistoryHelper";
import {PiList, PiListFill} from "react-icons/pi";
import SideMenu from "./SideMenu";
import TabHeader from "../tab/TabHeader";
import {iconTabStore} from "../tab/IconTabStore";
import {businessTabs} from "../tab/IconTabs";
import * as IconTabs from "../tab/IconTabs";
const CustomToggle = React.forwardRef(({ children, onClick, itemId, url}, ref) => (
    <div
        ref={ref}
        className={Styles.Menu}
        onMouseEnter={(e) => {
             appStore.selectedHeadMenu = itemId;
         }}
         onClick={(e) => {
             e.preventDefault();
             navigate(url)
             document.getElementById("scroll").scrollTo({left: 0, top: 0, behavior: "instant"})
         }}
    >
        {children}
    </div>
));

const CustomMenu = React.forwardRef(({children, style, className}, ref) => (
    <div ref={ref}
         style={style}
         className={`${className} ${Styles.DropdownMenu}` }
    >
        <ul>
            {children}
        </ul>
    </div>
));

const CustomItem = React.forwardRef(({children, style, className, onClick}, ref) => (
    <li ref={ref}
        style={style}
        className={className}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </li>
));

const RenderDropdownItems = (props) => {
    const {items} = props;
    return (
        <>
            {
                items.map((item, index) => {
                    return (
                        <Dropdown.Item key={index} as={CustomItem}
                                       onClick={() => {
                                           navigate(item.url)
                                           iconTabStore.selectedTab = item.key
                                           document.getElementById("scroll").scrollTo({left: 0, top: 0, behavior: "instant"})
                                       }}
                        >{item.label}
                        </Dropdown.Item>
                    )
                })
            }
        </>
    )
}

@observer
class Header extends Component {
    render() {
        let {headerColor} = this.props;
        return (
            <div className={Styles.HeaderContainer}>
                <div className={Styles.Header} style={{background: `${appStore.sideBarOpen ? '#FFFFFF' :  headerColor}`}}>
                <div className={Styles.Logo}
                         onClick={() => {
                             navigate(`/home`);
                             document.getElementById("scroll").scrollTo({left: 0, top: 0, behavior: "instant"})
                         }}
                    >
                        <Image src={`/asset/img/logo.svg`}/>
                    </div>
                    <div className={Styles.MenuList}>
                        <div className={Styles.Menu}
                             onClick={() => {
                                 navigate('/company')
                                 document.getElementById("scroll").scrollTo({left: 0, top: 0, behavior: "instant"})
                             }}
                        >
                            회사소개
                        </div>
                        <Dropdown show={appStore.selectedHeadMenu === 'business'} onToggle={() => appStore.selectedHeadMenu = ''}>
                            <Dropdown.Toggle as={CustomToggle} itemId={'business'} url={IconTabs.businessTabs[0].url}>
                                사업소개
                            </Dropdown.Toggle>
                            <Dropdown.Menu as={CustomMenu} >
                                <RenderDropdownItems items={IconTabs.businessTabs}/>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown show={appStore.selectedHeadMenu === 'material'} onToggle={() => appStore.selectedHeadMenu = ''}>
                            <Dropdown.Toggle as={CustomToggle} itemId={'material'} url={IconTabs.materialIcons[0].url}>
                                자재소개
                            </Dropdown.Toggle>
                            <Dropdown.Menu as={CustomMenu} >
                                <RenderDropdownItems items={IconTabs.materialIcons}/>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown show={appStore.selectedHeadMenu === 'hardware'} onToggle={() => appStore.selectedHeadMenu = ''}>
                            <Dropdown.Toggle as={CustomToggle} itemId={'hardware'} url={IconTabs.hardwareIcons[0].url}>
                                하드웨어
                            </Dropdown.Toggle>
                            <Dropdown.Menu as={CustomMenu} >
                                <RenderDropdownItems items={IconTabs.hardwareIcons}/>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className={Styles.Menu} onClick={() => {
                            appStore.isCustomerService = true;
                            navigate('/company')
                        }}>
                            고객센터
                        </div>
                    </div>
                    <div className={Styles.Hamburger} onClick={() => {
                        appStore.sideBarOpen = !appStore.sideBarOpen
                    }}>
                        <PiList size={'2rem'}/>
                    </div>
                </div>
            </div>
        );
    }
}

Header.propTypes = {};

export default Header;