import React, {Component} from 'react';
import Styles from './TabHeader.module.scss'
import IconTab from "./IconTab";
import {iconTabStore} from "./IconTabStore";
import appStore from "../../store/AppStore";

class TabHeader extends Component {

    render() {
        let headerColor = appStore.scrollTop > 0 ? 'var(--bs-white)' : '#f2f2f2';
        const {items} = this.props;
        return (
            <div className={Styles.TabHeaderContainer} style={{background:headerColor}}>
                <IconTab
                    isLabel={false}
                    store={iconTabStore}
                    items={items}
                />
            </div>
        );
    }
}

export default TabHeader;