import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Styles from './CompanyView.module.scss'
import {Button, Card, Col, Container, Image, Row} from "react-bootstrap";
import {FiPhone} from "react-icons/fi";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {MdContentCopy, MdOutlineDoorSliding} from "react-icons/md";
import {IoIosArrowForward} from "react-icons/io";
import FadeCarousel from "../component/animate/FadeCarousel";
import {isMobile} from "react-device-detect";
import HomeCarouselItem from "../component/carousel/HomeCarouselItem";
import {AiOutlineLink, AiOutlinePhone} from "react-icons/ai";
import CommonHelper from "../helper/CommonHelper";
import notifications from "react-notifications/lib/Notifications";
import appStore from "../store/AppStore";


import ScrollFadeIn from "../component/animate/ScrollFadeIn";
import CompanyContent from "./CompanyViewContent.json";
import HardwareContent from "./HardwareViewContent.json";
import HorizonLine from "../component/Decoration/HorizonLine";
import {ReactComponent as FormIcon} from "../assets/home/form.svg";
import GoogleMapReact from 'google-map-react';

const position = {lat: 37.482102, lng: 126.663955};


const CustomDot = ({onClick, active, index, carouselState}) => {
    const {currentSlide} = carouselState;
    return (
        <div className={`${Styles.Dot} ${active ? Styles.Active : ''}`} onClick={() => onClick()}/>
    );
}

const Material = ({icon, title, description, link}) => {

    return (
        <div className={Styles.Library}>
            <div className={Styles.Icon}>
                <Image src={icon}/>
            </div>
            <div className={`${Styles.Title}`}>
                {title}
            </div>
            <div className={`${Styles.Description} `}>
                {description}
            </div>
            <div className={Styles.Buttons}>
                <Button variant={"outline-secondary"} className={Styles.Button}>
                    <IoIosArrowForward className={`me-1`}/> 제품보기
                </Button>
            </div>
        </div>
    )
}

@observer
class CompanyView extends Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }
    componentDidMount() {

        if (appStore.isCustomerService) {
            this.ref.current.scrollIntoView({behavior: 'instant'})
            appStore.isCustomerService = false;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (appStore.isCustomerService) {
            this.ref.current.scrollIntoView({behavior: 'instant'})
            appStore.isCustomerService = false;
        }

    }

    render() {
        return (
            <div>
                <div className={Styles.HeaderContent}>
                    <div className={Styles.Container}>
                        <Col sm={12} md={6} className={`p-0 align-items-center`}>
                            <div className={Styles.Content}>
                                <div className={`${Styles.LightTitle} `}>Company Info</div>
                                <div className={`${Styles.LightTitleBold} `}>회사소개</div>
                                <div className={`${Styles.Description} `}>(주)형제씽크에 오신 모든 분들께 감사드립니다.</div>
                            </div>
                        </Col>
                        <Col sm={12} md={6} className={`p-0 d-flex align-items-center`}>
                            <div className={Styles.ImageContent}>
                                <Image src={`/asset/img/company/header_content_image.png`}
                                       style={{width: '100%', height: '100%'}}/>

                            </div>
                        </Col>

                    </div>
                </div>

                <div className={Styles.IntroContainer}>
                    <div className={Styles.Content}>
                        <ScrollFadeIn
                            direction={'up'}
                            duration={0.5}
                            delay={0.1}
                        >
                            <div className={`${Styles.Title} `}>
                                인테리어 사업자에게 꼭 필요한 맞춤가구 공장
                            </div>
                            <div className={Styles.SubTitle}>
                                바로 형제씽크 입니다.
                            </div>
                        </ScrollFadeIn>
                        <ScrollFadeIn
                            direction={'up'}
                            duration={1}
                            delay={0.1}
                        >
                            <div className={Styles.Image}>
                                <Image src={`/asset/img/company/company_work_image.jpeg`}/>
                            </div>
                        </ScrollFadeIn>
                        <ScrollFadeIn
                            direction={'up'}
                            duration={1}
                            delay={0.1}
                        >
                            <div className={Styles.DescriptionTitle}>
                                형제씽크는 씽크대, 붙박이장, 신발장 등 맞춤가구 제작을 전문으로 하는 기업입니다.
                            </div>
                            <div className={Styles.Description}>
                                유통 단계가 없이 직접 주문하는 공장 발주 시스템으로 여러 인테리어 파트너사와 함께 할 수 있도록 형제씽크는 늘 열려 있습니다.{`\n`}
                                끊임없는 연구 개발과 기술력으로 퀄리티 있는 제품을 다양한 현장에 맞게 시공까지 책임지고 있는 형제씽크는 인테리어 사업자, 전원주택 시공, 오피스텔·빌라
                                시공등 다양한 경험을 보유하고 있으며 상업시설, 공공기관 등의 시공 생산도 하고 있습니다.{`\n`}
                                공장 내부 인력으로 현장 실측을 100% 담당하고 있으며 철거부터 시공까지 막힘없는 원스톱 서비스에 자부심을 가지고 있습니다. 원하는 모양의 맞춤가구를
                                생산하는 전문 기업으로 규모가 큰 공사나 다양한 자재를 취급해야 하는 공사도 문제 없이 진행 가능합니다.
                            </div>
                        </ScrollFadeIn>
                    </div>
                </div>

                <div className={Styles.ServiceContent}>
                    <ScrollFadeIn
                        direction={'up'}
                        duration={0.5}
                        delay={0.1}
                    >
                        <div className={Styles.Content}>
                            <span className={`${Styles.Title}`}>
                                언제나 최고의 제품으로{`\n`}
                            </span>
                            <span className={`${Styles.SubTitle}`}>
                                모든 고객분들에게
                                {`\n`}
                                보답하겠습니다.
                            </span>

                        </div>
                    </ScrollFadeIn>
                    <div className={Styles.ContactCards} ref={this.ref}>
                        <div className={`d-flex justify-content-center flex-grow-1`}>
                            <ScrollFadeIn
                                direction={'up'}
                                duration={1}
                                delay={0.1}
                            >
                                <div className={Styles.ContactCard}>
                                    <div className={Styles.Icon}>
                                        <AiOutlinePhone/>
                                    </div>
                                    <div className={Styles.Title}>
                                        대량/사업자 문의
                                    </div>
                                    <div className={Styles.Description}>
                                        공장 전화번호 : 032-572-5817{`\n`}
                                        공장 팩스번호 : 032-573-5817{`\n`}
                                        이메일주소 : 200interior@gmail.com
                                    </div>
                                    <div className={Styles.Button}>
                                        <Button variant={"outline-success"} size={"lg"} onClick={() => {
                                            appStore.contactPanelOpen = !appStore.contactPanelOpen;
                                        }}>
                                            <FormIcon/> 간편 문의 하기
                                        </Button>
                                    </div>
                                </div>
                            </ScrollFadeIn>
                        </div>
                        <div className={`d-flex justify-content-center flex-grow-1`}>
                            <ScrollFadeIn
                                direction={'up'}
                                duration={1}
                                delay={0.1}
                            >
                                <div className={Styles.ContactCard}>
                                    <div className={Styles.Icon}>
                                        <AiOutlinePhone/>
                                    </div>
                                    <div className={Styles.Title}>
                                        일반 소비자 문의(이백인테리어)
                                    </div>
                                    <div className={Styles.Description}>
                                        사무실 전화번호 : 031-716-2228{`\n`}
                                        사무실 팩스번호 : 031-716-2229{`\n`}
                                        이메일주소 : 200interior@gmail.com
                                    </div>
                                    <div className={Styles.Button}>
                                        <Button variant={"outline-success"} size={"lg"}>
                                            <AiOutlineLink size={`1.5rem`} className={`me-1`}/>간편 문의 하기
                                        </Button>
                                    </div>
                                </div>
                            </ScrollFadeIn>
                        </div>
                    </div>
                </div>
                <div className={Styles.MemberContent}>
                    <Container className={Styles.Container}>
                        <ScrollFadeIn
                            direction={'up'}
                            duration={0.5}
                            delay={0.1}
                        >
                            <div className={Styles.Content}>
                                <div className={Styles.Title}>
                                    씽크대&붙박이장의 모든 것, 저희가 도와드리겠습니다.

                                </div>
                                <div className={Styles.Description}>
                                    (주)형제씽크 구성원 소개
                                </div>

                        </div>
                    </ScrollFadeIn>
                    {
                        CompanyContent.teams.map((team, index) => (
                            <ScrollFadeIn
                                className={Styles.Team}
                                key={index}
                                direction={'up'}
                                duration={1}
                                delay={0.1}
                            >
                                <div className={Styles.Title}>
                                    {team.label}
                                </div>
                                <div className={Styles.MemberGrid}>
                                    {
                                        team.members.map((member, index) =>
                                            <div key={index} className={Styles.Member}>
                                                <div className={Styles.Type}>
                                                    {member.title}
                                                </div>
                                                <div className={Styles.Name}>
                                                    {member.name}
                                                </div>
                                                {
                                                    member?.tel &&
                                                    <div className={Styles.Phone}>
                                                        {member.tel}
                                                    </div>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                                {
                                    index < CompanyContent.teams.length - 1 &&
                                    <HorizonLine color={"#6C757D"} stroke={"0.0625rem"}/>
                                }
                            </ScrollFadeIn>
                        ))
                    }
                    </Container>
                </div>
                <div className={Styles.MapContent}>
                    <ScrollFadeIn
                        direction={'up'}
                        duration={0.5}
                        delay={0.1}
                    >
                        <div className={Styles.Content}>
                            <div className={`${Styles.Title}`}>
                                오시는 길
                            </div>

                            <div className={`${Styles.Description}`}>(주) 형제씽크에 방문해 주신 모든 고객님들께 감사드립니다.</div>
                        </div>
                    </ScrollFadeIn>
                    <ScrollFadeIn
                        direction={'up'}
                        duration={1}
                        delay={0.1}
                    >

                        <div className={Styles.Map}>
                            <GoogleMapReact
                                options={{
                                    mapTypeControl: true,
                                    scrollwheel: false,
                                    zoomControl: true,
                                    zoomControlOptions: {
                                        position: 8,
                                    },
                                    scaleControl: true,

                                }}
                                bootstrapURLKeys={{key: 'AIzaSyDxx0f_CMubae1vqSdKiiAso0-EsMVKBB4'}}
                                defaultCenter={position}
                                yesIWantToUseGoogleMapApiInternals={true}
                                defaultZoom={15}
                                onGoogleApiLoaded={({map, maps}) => {
                                    let marker = new maps.Marker({
                                        position: position,
                                        map,

                                    });
                                }}
                            >
                            </GoogleMapReact>

                        </div>
                        <div className={Styles.Address}>
                            인천광역시 동구 방축로 177번길 36, b동 1층 우측(송림동)
                            <Button variant={"outline-secondary"}
                                    className={`ms-2 border-0`}
                                    size={`sm`}
                                    onClick={() => {
                                        navigator.clipboard.writeText('인천광역시 동구 방축로 177번길 36, b동')
                                    }}
                            >
                                <MdContentCopy size={`1.2rem`}

                                />
                            </Button>
                        </div>
                    </ScrollFadeIn>
                </div>
            </div>
        );
    }
}

export default CompanyView;
