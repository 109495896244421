import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from "mobx-react";
import Styles from "./ContactPanel.module.scss";
import {navigate} from "../../helper/HistoryHelper";
import appStore from "../../store/AppStore";
import {Button, Col, FormControl, FormLabel, Image} from "react-bootstrap";
import {TbChevronRightPipe} from "react-icons/tb";
import {AiOutlineForm} from "react-icons/ai";
import CommonHelper from "../../helper/CommonHelper";

@observer
class ContactPanel extends Component {

    render() {

        return (
            <div className={`${Styles.ContactPanel} ${appStore.contactPanelOpen ? Styles.Open : ''}`} >
                <div className={Styles.TopHandle}>
                    <Image src={`/asset/img/top-handle.png`}
                           onClick={()=>{
                               appStore.contactPanelOpen = !appStore.contactPanelOpen;
                           }}
                    />
                </div>
                <div className={Styles.ContentContainer}>
                    <div className={Styles.Content}>
                        <div className={Styles.Form}>
                            <div className={Styles.Header}>
                                {
                                    CommonHelper.range(0,6).map(
                                        idx=><div className={Styles.Dot}></div>
                                    )
                                }
                            </div>
                            <div className={Styles.Title}>
                                <AiOutlineForm/> 대량/사업자문의
                            </div>
                            <div className={Styles.Row}>
                                <Col md={3}>
                                    <FormLabel>
                                        업체명
                                    </FormLabel>
                                </Col>
                                <Col md={9}>
                                    <FormControl className={Styles.Input} placeholder={'입력하세요.'}/>
                                </Col>
                            </div>
                            <div className={Styles.Row}>
                                <Col md={3}>
                                    <FormLabel>
                                        연락처
                                    </FormLabel>
                                </Col>
                                <Col md={9}>
                                    <FormControl className={Styles.Input} placeholder={'입력하세요.'}/>
                                </Col>
                            </div>
                            <div className={Styles.Row}>
                                <Col md={3}>
                                    <FormLabel>
                                        내용
                                    </FormLabel>
                                </Col>
                                <Col md={9}>
                                    <FormControl as={"textarea"} className={Styles.Input} placeholder={'입력하세요.'}/>
                                </Col>
                            </div>
                            <div className={`${Styles.Input} ${Styles.Row}`}>
                                <Col md={9}>
                                    <FormLabel>
                                        사업자등록증
                                    </FormLabel>
                                </Col>
                                <Col md={3}>
                                    <Button variant={"outline-secondary"} size={"sm"}>파일 업로드</Button>
                                </Col>
                            </div>
                            <hr/>

                            <div className={`${Styles.Input} d-flex flex-column`}>
                                <Button variant={"outline-primary"} >대량/사업자 문의하기</Button>
                            </div>
                        </div>
                        <div className={Styles.ContentCover}>
                            <div className={Styles.HandleBar}></div>
                        </div>

                    </div>
                    <div className={Styles.RightHandle}>
                        <Image src={`/asset/img/handle.png`}
                               onClick={()=>{
                                   appStore.contactPanelOpen = !appStore.contactPanelOpen;
                               }}
                        />

                    </div>
                </div>
            </div>

        );
    }
}

ContactPanel.propTypes = {};

export default ContactPanel;