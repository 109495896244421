import React, {Component} from 'react';
import {Redirect, Route, Router, Switch, BrowserRouter} from 'react-router-dom';
import {observer} from 'mobx-react';
import {HISTORY} from './helper/HistoryHelper';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import {LoginDialog} from "./component/dialog/LoginDialog";
import {loginDialogStore} from "./store/LoginDialogStore";
import HomeView from "./view/HomeView";
import Header from "./component/layout/Header";
import Styles from './App.module.scss'
import appStore from "./store/AppStore";
import Footer from "./component/layout/Footer";
import MaterialView from "./view/MaterialView";
import HardwareView from "./view/HardwareView";
import CompanyView from "./view/CompanyView";
import SideMenu from "./component/layout/SideMenu";
import ContactPanel from "./component/layout/ContactPanel";
import BusinessView from "./view/BusinessView";

@observer
class App extends Component {
    render() {
        let headerColor = appStore.scrollTop > 0 ? 'var(--bs-white)' : '#f2f2f2';
        return (
            <GoogleOAuthProvider clientId="276964584825-e1abfn8n7q4cjh8684av08vdb4dbjj71.apps.googleusercontent.com">
                <div id={"scroll"} className={Styles.Container} onScroll={appStore.scrollHandler}>
                    <Header headerColor={headerColor}/>

                    <div>
                        <Router history={HISTORY}>
                            <Switch>
                                <Route path="/home" component={HomeView}/>
                                <Route path="/company" component={CompanyView}/>
                                <Route path="/business/:type" component={BusinessView}/>
                                <Route path="/hardware/:type" component={HardwareView}/>
                                <Route path="/material/:type" component={MaterialView}/>
                                <Redirect to="/home" exact="/"/>
                            </Switch>
                        </Router>
                        <Footer/>
                    </div>
                    <LoginDialog store={loginDialogStore}/>
                </div>
                <SideMenu headerColor={headerColor}/>
                <ContactPanel/>
            </GoogleOAuthProvider>
        );
    }
}

export default App;
