import React, {Component} from 'react';
import {Image} from "react-bootstrap";
import Styles from './FadeCarousel.module.scss'

class FadeCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index : undefined
        }
    }

    componentDidMount() {
        let {images, changeDelay = 2500} = this.props
        this.setState({index:0})
        setInterval(()=>{
            this.setState({index: this.state.index < images.length - 1 ? this.state.index + 1 : 0 })
        }, changeDelay)
    }

    getAnimation = (idx) =>{
        let {images, delay = 2} = this.props
        if(idx===this.state.index){
            return Styles.FadeIn;
        }else if((this.state.index === 0 && idx === images.length -1) || (this.state.index - 1 === idx)){
            return Styles.FadeOut;
        }else{
            return '';
        }
    }

    render() {
        let {images, transition} = this.props;
        return (
            <div className={Styles.FadeCarousel}>
                {
                    images.map((src,idx)=>
                        <Image src={src} className={`${Styles.Image} ${this.getAnimation(idx)}`} key={`FADE_CAROUSEL_${idx}`} style={{transition:`all ${transition}s`}}/>
                    )
                }

            </div>
        );
    }
}

FadeCarousel.propTypes = {};

export default FadeCarousel;