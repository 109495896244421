import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Card, Image} from "react-bootstrap";
import Styles from "./HomeCarouselItem.module.scss";

class HomeCarouselItem extends Component {
    render() {
        let { src, title, description} = this.props;
        return (
            <Card className={Styles.CarouselItem} >
                <Image src={src}/>
                <div className={Styles.Content}>
                    <div className={`${Styles.Title}`}>{title}</div>
                    <div className={`${Styles.Description} `}>{description}</div>
                </div>
            </Card>
        )
    }
}

HomeCarouselItem.propTypes = {};

export default HomeCarouselItem;