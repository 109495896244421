import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Styles from './HomeView.module.scss'
import {Button, Card, Col, Image, Row} from "react-bootstrap";
import {FiPhone} from "react-icons/fi";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {MdOutlineDoorSliding} from "react-icons/md";
import {IoIosArrowForward} from "react-icons/io";
import FadeCarousel from "../component/animate/FadeCarousel";
import {isMobile} from "react-device-detect";
import HomeCarouselItem from "../component/carousel/HomeCarouselItem";
import appStore from "../store/AppStore";
import Lottie from "lottie-react";
import ConcentLottie from '../component/animate/lottie/concent_icon.json'
import CounterTopLottie from '../component/animate/lottie/countertop_icon.json'
import DoorLottie from '../component/animate/lottie/door_icon.json'
import FaucetLottie from '../component/animate/lottie/faucet_icon.json'
import HandleLottie from '../component/animate/lottie/handle_icon.json'
import HingeLottie from '../component/animate/lottie/hinge_icon.json'
import HoodLottie from '../component/animate/lottie/hood_icon.json'
import RangeLottie from '../component/animate/lottie/range_icon.json'
import SinkbowlLottie from '../component/animate/lottie/sinkbowl_icon.json'
import {navigate} from "../helper/HistoryHelper";
import {ReactComponent as FormIcon} from '../assets/home/form.svg';

const CustomDot = ({ onClick, active, index, carouselState }) => {
    const { currentSlide } = carouselState;
    return (
        <div className={`${Styles.Dot} ${active ? Styles.Active : ''}`} onClick={() => onClick()}/>
    );
}

const Material = ({lottie, title, description, link}) => {

    return (
        <div className={Styles.Library}>
            <div className={Styles.Icon}>
                <Lottie animationData={lottie} />
            </div>
            <div className={`${Styles.Title}`}>
                {title}
            </div>
            <div className={`${Styles.Description} `}>
                {description}
            </div>
            <div className={Styles.Buttons}>
                <Button variant={"outline-secondary"} className={Styles.Button}
                    onClick={() => {
                        navigate(link);
                        document.getElementById("scroll").scrollTo({left: 0, top: 0, behavior: "instant"});
                    }}
                >
                    <IoIosArrowForward className={`me-1`} /> 제품보기
                </Button>
            </div>
        </div>
    )
}
@observer
class HomeView extends Component {
    materialList = [
        {
            lottie:DoorLottie,
            title:'도어',
            description:`친환경 E0 보드 부터${'\n'}고급스러운 도장도어,${'\n'}다양한 색상과 광택!`,
            link: '/material/door'
        },
        {
            lottie:CounterTopLottie,
            title:'상판',
            description:`가성비 끝판왕 인조대리석,${'\n'}프리미엄 자재 세라믹 상판,${'\n'}유니크함의 칸스톤 상판`,
            link: '/material/countertop'
        },
        {
            lottie:HandleLottie,
            title:'손잡이',
            description:`상부에 많이 사용하는 핸드리스,${'\n'}하부에 인기있는 목찬넬,${'\n'}각 상황에 맞는 다양한 손잡이`,
            link: '/material/handle'
        },
        {
            lottie:FaucetLottie,
            title:'수전',
            description:`현장 상황에 꼭 맞는${'\n'}주방 인테리어를 위한${'\n'}일반/거위목 수전`,
            link: '/hardware/faucet'
        },
        {
            lottie:SinkbowlLottie,
            title:'싱크볼',
            description:`시공방식, 재질, 모양, 개수에 따른${'\n'}싱글, 더블, 점보로 이루어진${'\n'}다양한 일반/사각 싱크볼`,
            link: '/hardware/sinkbowl'
        },
        {
            lottie:HingeLottie,
            title:'경첩/레일',
            description:`어떤 스타일이든 적용 가능한${'\n'}경첩 : 일반, 뎀핑, 블럼 경첩${'\n'}레일 : 볼레일, 언더, 블럼 레일`,
            link: '/material/hinge'
        },
        {
            lottie:RangeLottie,
            title:'렌지',
            description:`가스렌지, 인덕션${'\n'}가장 많이 사용하는 3구 렌지${'\n'}린나이, SK`,
            link: '/hardware/range'
        },
        {
            lottie:HoodLottie,
            title:'후드',
            description:`주방을 언제나 상쾌하게,${'\n'}다양한 크기로 어디에나 착!${'\n'}슬라이드, 하이드, 침니, 슬림`,
            link: '/hardware/hood'
        },
        {
            lottie:ConcentLottie,
            title:'빌트인 콘센트',
            description:`트렌디한 스타일의 콘센트${'\n'}공간활용도 업!${'\n'}무선충전 까지 가능한 제품`,
            link: '/hardware/outlet'
        },
    ]

    carouselData = [
        {
            src:`/asset/img/home/carousel/main_slide_01.png`,
            title:'현장 상황에 꼭 맞는 주방 인테리어',
            description:`Kitchen Interior`,
            link: ''
        },
        {
            src:`/asset/img/home/carousel/main_slide_02.png`,
            title:'라이프 스타일을 반영한 맞춤 드레스룸',
            description:`Built-In System Dress`,
            link: ''
        },
        {
            src:`/asset/img/home/carousel/main_slide_03.png`,
            title:'입구부터 수려한 신발장 제작',
            description:`Shoe closet`,
            link: ''
        },
        {
            src:`/asset/img/home/carousel/main_slide_04.png`,
            title:'고객이 원하는 각종 수납장 맞춤제작',
            description:`Storage cabinet`,
            link: ''
        },
    ]

    renderCarouselContent = (data) => {


    }
    render() {
        return (
            <div>
                <div className={Styles.HeaderContent}>
                    <div className={Styles.Container}>
                        <Col sm={12} md={6} className={`p-0 align-items-center`}>
                            <div className={Styles.Content}>
                                <div className={`${Styles.LightTitle} `}>Interior ?</div>
                                <div className={`${Styles.LightTitleBold} `}>자재의 기준을 바꾸다.</div>
                                <div className={`${Styles.Description} `}>붙박이장, 싱크대, 맞춤가구의 모든것을 만나보세요.</div>
                                <div className={Styles.Button}>
                                    <Button variant={"outline-secondary"} className={Styles.RadiusButton}
                                            onClick={()=>{appStore.contactPanelOpen = !appStore.contactPanelOpen;}}
                                    >
                                        <FormIcon /> 대량/사업자 문의하기
                                    </Button>
                                </div>

                            </div>
                        </Col>
                        <Col sm={12} md={6} className={`p-0 d-flex align-items-center`}>
                            <div className={Styles.ImageContent}>
                                <FadeCarousel changeDelay={2500}
                                              transition={0.6}
                                              images={[
                                                  `/asset/img/home/content/main_image_01.png`,
                                                  `/asset/img/home/content/main_image_02.png`,
                                                  `/asset/img/home/content/main_image_03.png`,
                                                  `/asset/img/home/content/main_image_04.png`,
                                                  `/asset/img/home/content/main_image_05.png`,
                                              ]}
                                />

                            </div>
                        </Col>

                    </div>
                </div>

                <div className={Styles.CarouselContainer}>
                    <div className={Styles.Content}>
                        <div className={`${Styles.Title} `}>
                            형제씽크 사업 영역
                        </div>
                        <div className={Styles.Description}>
                            다양한 인테리어 현장에 트렌디한 디자인의 맞춤형 시공 서비스를 제공합니다.
                            {`\n`}
                            대량작업 및 인테리어 사업자와 매끄럽게 협업할 수 있는 튼튼한 기업으로 공장직영 소비자 상담 운영도 함께 하고 있습니다.
                        </div>
                    </div>
                    <div className={Styles.MobileCarousel}>
                        <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlaySpeed={3000}
                            className=""
                            containerClass=""
                            dotListClass={Styles.DotList}
                            customDot={<CustomDot/>}
                            draggable
                            focusOnSelect={false}
                            infinite
                            itemClass={''}
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            renderArrowsWhenDisabled={false}
                            renderButtonGroupOutside={true}
                            renderDotsOutside={true}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 0
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 0
                                },

                            }}
                            rewind={false}
                            rewindWithAnimation={false}
                            rtl={false}
                            shouldResetAutoplay
                            showDots
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                        >
                            {
                                this.carouselData.map(data=>
                                    <HomeCarouselItem {...data}/>
                                )
                            }
                        </Carousel>
                    </div>
                    <div className={Styles.Carousel}>
                        <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlaySpeed={3000}
                            centerMode
                            partialVisibility
                            className=""
                            containerClass=""
                            dotListClass={Styles.DotList}
                            customDot={<CustomDot/>}
                            draggable
                            focusOnSelect={false}
                            infinite
                            itemClass={''}
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            renderArrowsWhenDisabled={false}
                            renderButtonGroupOutside={true}
                            renderDotsOutside={true}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 0
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 0
                                },

                            }}
                            rewind={false}
                            rewindWithAnimation={false}
                            rtl={false}
                            shouldResetAutoplay
                            showDots
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                        >
                            {
                                this.carouselData.map(data=>
                                    <HomeCarouselItem {...data}/>
                                )
                            }
                        </Carousel>
                    </div>

                </div>

                <div className={Styles.ServiceContent}>
                    <div className={Styles.Content}>
                        <div className={`${Styles.Title}`}>
                            파트너사의 성공이 바로 우리의 성공입니다.
                        </div>
                        <div className={`${Styles.SubTitle}`}>
                            씽크대, 붙박이장, 각종 수납장 맞춤제작 현장 실측부터 3D도면 작업과 검수까지
                        </div>
                        <div className={`${Styles.Description}`}>
                            다양한 현장 경험과 노하우로 파트너사의 니즈를 캐치합니다.
                            {`\n`}
                            계약과 소비자 상담 등 중요한 업무에 집중하실 수 있도록 형제씽크가 도와드리겠습니다.
                            {`\n`}
                            충분히 신뢰할 수 있는 A/S기간 까지 보장해 드립니다.
                        </div>
                    </div>
                    <Image src={`/asset/img/home/spaceLine3Drender.png`} style={{width:'100%'}}/>
                </div>
                <div className={Styles.MaterialContent}>
                    <div className={Styles.Content}>
                        <div className={Styles.Title}>
                            자재 & 하드웨어
                        </div>
                        <div className={Styles.Description}>
                            친환경 자재부터 트렌디한 디자인, 많은 사랑을 받은 대표 자재와 하드웨어를 소개합니다.
                        </div>

                    </div>

                    <div className={Styles.LibraryGrid}>
                        {
                            this.materialList.map((material, idx)=>
                                <Material {...material} key={idx}/>
                            )
                        }
                    </div>
                </div>
                <div className={Styles.ContactContent}>
                    <div className={Styles.Content}>
                        <div className={`${Styles.Title}`}>
                            지금 연락주세요
                            {`\n`}
                            대량시공 & 기업고객
                        </div>

                        <div className={`${Styles.Description}`}>대량시공이나 기업고객을 위한 간편한 견적문의가 가능합니다.</div>
                        <div className={`d-flex justify-content-center`}>
                            <Button variant={"outline-secondary"} className={Styles.RadiusButton}
                                    onClick={()=> {appStore.contactPanelOpen = !appStore.contactPanelOpen;}}
                            >
                                <FormIcon /> 대량/사업자 문의하기
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeView;
