import React from "react";
import {ReactComponent as Sinkbowl} from "../../assets/hardware/sinkbowl.svg";
import {ReactComponent as Faucet} from "../../assets/hardware/faucet.svg";
import {ReactComponent as Hood} from "../../assets/hardware/hood.svg";
import {ReactComponent as Range} from "../../assets/hardware/range.svg";
import {ReactComponent as Outlet} from "../../assets/hardware/outlet.svg";
import {ReactComponent as Door} from "../../assets/meterial/door.svg";
import {ReactComponent as CounterTop} from "../../assets/meterial/countertop.svg";
import {ReactComponent as Handle} from "../../assets/meterial/handle.svg";
import {ReactComponent as Hinge} from "../../assets/meterial/hinge.svg";
export const hardwareIcons = [
    {
        key: "sinkbowl",
        label: "씽크볼",
        url:"/hardware/sinkbowl",
        icon: <Sinkbowl/>
    },
    {
        key: "faucet",
        label: "수전",
        url:"/hardware/faucet",
        icon: <Faucet/>
    },
    {
        key: "hood",
        label: "후드",
        url:"/hardware/hood",
        icon: <Hood/>
    },
    {
        key: "range",
        label: "렌지",
        url:"/hardware/range",
        icon: <Range/>
    },
    {
        key: "outlet",
        label: "빌트인\n콘센트",
        url:"/hardware/outlet",
        icon: <Outlet/>
    }
]

export const materialIcons = [
    {
        key: "door",
        label: "도어",
        url:"/material/door",
        icon: <Door/>
    },
    {
        key: "countertop",
        label: "상판",
        url:"/material/countertop",
        icon: <CounterTop/>
    },
    {
        key: "handle",
        label: "손잡이",
        url:"/material/handle",
        icon: <Handle/>
    },
    {
        key: "hinge",
        label: "경첩&레일",
        url:"/material/hinge",
        icon: <Hinge/>
    }
]

export const businessTabs = [
    {
        key: "sink",
        label: "씽크대",
        url: "/business/sink"
    },
    {
        key: "builtIn",
        label: "빌트인",
        url: "/business/builtIn"
    }
]