import {makeObservable, observable} from "mobx";

class IconTabStore {

    @observable
    selectedTab;
    constructor() {
        makeObservable(this);
    }
}

const iconTabStore = new IconTabStore();

export {iconTabStore};