import {action, computed, makeObservable, observable} from 'mobx';



class LoginDialogStore {

    constructor() {
        makeObservable(this);
    }

    @observable
    show = false;

    open(){
        this.show = true;
    }
    close(){
        this.show = false;
    }

}

export const loginDialogStore = new LoginDialogStore();

