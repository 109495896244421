import React, {Component} from 'react';
import {observer} from "mobx-react";
import Styles from "./HardwareView.module.scss";
import {Col, Container, Image} from "react-bootstrap";
import HardwareContent from "./HardwareViewContent.json";
import IconTab from "../component/tab/IconTab";
import HorizonLine from "../component/Decoration/HorizonLine";
import ScrollFadeIn from "../component/animate/ScrollFadeIn";
import * as IconTabs from "../component/tab/IconTabs";
import {iconTabStore} from "../component/tab/IconTabStore";
import TabHeader from "../component/tab/TabHeader";


const HardwareRender = (props) => {
    const {type} = props;
     return (
        <>
            {
                type && IconTabs.hardwareIcons.map(icon => icon.key).includes(type) &&
                <>
                    <div className={Styles.HeaderContent}>
                        <div className={`flex-grow-1 p-0 d-flex flex-wrap`}>
                            <Col sm={12} md={6} className={`p-0 align-items-center`}>
                                <div className={Styles.Content}>
                                    <div className={`${Styles.LightTitle} `}>Special design kitchen</div>
                                    <div className={`${Styles.LightTitleBold} `}>하드웨어
                                        / {HardwareContent[type].title}</div>
                                    <div className={`${Styles.Description} `}>유니크한 주방 디자인, 여기 하드웨어에서 시작됩니다.</div>
                                </div>
                            </Col>
                            <Col sm={12} md={6} className={`p-0 d-flex justify-content-end align-items-center`}>
                                <Image className={Styles.Image}
                                       src={HardwareContent[type].imageUrl}/>
                            </Col>
                        </div>
                    </div>
                    <div className={Styles.Section}>
                        <Container className={Styles.Container}>
                            <div className={Styles.Tabs}>
                                <ScrollFadeIn
                                    direction={'up'}
                                    duration={0.5}
                                    delay={0.1}
                                >
                                    <IconTab
                                        items={IconTabs.hardwareIcons}
                                    />
                                </ScrollFadeIn>
                            </div>
                            <ScrollFadeIn
                                direction={'up'}
                                duration={0.5}
                                delay={0.1}
                            >
                                <div className={Styles.Content}>
                                    <div
                                        className={Styles.Title}>{HardwareContent[type].subject}</div>
                                    <div
                                        className={Styles.Description}>{HardwareContent[type].content}</div>
                                </div>
                            </ScrollFadeIn>
                            {
                                HardwareContent[type].data.map((content, index) => {
                                    return (
                                        <>
                                            <div key={index} className={Styles.Data}>
                                                <ScrollFadeIn
                                                    direction={'up'}
                                                    duration={0.5}
                                                    delay={0.1}
                                                >
                                                    <div className={Styles.TitleContainer}>
                                                        <div className={Styles.MainTitle}>{content.mainTitle}</div>
                                                        <div className={Styles.SubTitle}>{content.subTitle}</div>
                                                    </div>
                                                </ScrollFadeIn>
                                                {
                                                    (content.type && content.type === 'desc') ?
                                                        <div className={Styles.DescContainer}>
                                                            {
                                                                content.images?.map((image, index) => {
                                                                    return (
                                                                        <ScrollFadeIn
                                                                            key={index}
                                                                            direction={'up'}
                                                                            duration={1}
                                                                            delay={0.1}
                                                                        >
                                                                            <div key={index} className={Styles.Item}>
                                                                                <div
                                                                                    className={'d-flex align-items-center'}>
                                                                                    <Image className={'w-100'}
                                                                                           src={image.url}/>
                                                                                </div>
                                                                                <div key={index}
                                                                                     className={Styles.Desc}>
                                                                                    <div className={Styles.Title}>
                                                                                        {image.label}
                                                                                    </div>
                                                                                    {
                                                                                        image.desc?.map((d, index) => {
                                                                                            return (
                                                                                                <div key={index}>
                                                                                                    <div
                                                                                                        className={Styles.Subject}>
                                                                                                        {d.subject}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className={Styles.Content}>
                                                                                                        {d.content}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </ScrollFadeIn>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        :
                                                        <div className={Styles.ImageContainer}>
                                                            {
                                                                content.images?.map((image, index) => {
                                                                    return (
                                                                        <div key={index} className={Styles.Item}>
                                                                            <ScrollFadeIn
                                                                                direction={'up'}
                                                                                duration={1}
                                                                                delay={0.1}
                                                                            >
                                                                                <Image className={'w-100'}
                                                                                       src={image.url}/>
                                                                                <div
                                                                                    className={Styles.Label}>{image.label}</div>
                                                                            </ScrollFadeIn>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                }

                                            </div>
                                            {
                                                index < HardwareContent[type].data.length - 1 &&
                                                <HorizonLine color={"#6C757D"} stroke={"0.0625rem"}/>
                                            }
                                        </>
                                    )
                                })
                            }
                        </Container>
                    </div>
                </>
            }
        </>
    )
}


@observer
class HardwareView extends Component {
    componentDidMount() {
        let {match: {params}} = this.props;
        if(params && params.type){
            iconTabStore.selectedTab = params.type;
        }else{
            iconTabStore.selectedTab = IconTabs.hardwareIcons[0].key;
        }
    }

    render() {
        return (
            <div className={'d-flex flex-column align-items-center'}>
                <TabHeader items={IconTabs.hardwareIcons} />
                {iconTabStore.selectedTab === 'sinkbowl' && <HardwareRender type={iconTabStore.selectedTab} />}
                {iconTabStore.selectedTab === 'faucet' && <HardwareRender type={iconTabStore.selectedTab} />}
                {iconTabStore.selectedTab === 'hood' && <HardwareRender type={iconTabStore.selectedTab} />}
                {iconTabStore.selectedTab === 'range' && <HardwareRender type={iconTabStore.selectedTab} />}
                {iconTabStore.selectedTab === 'outlet' && <HardwareRender type={iconTabStore.selectedTab} />}
            </div>
        );
    }
}

export default HardwareView;