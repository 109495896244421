import React, {Component} from 'react';

class ScrollFadeIn extends Component {

    dom;

    io;
    constructor(props) {
        super(props);
        this.dom = React.createRef();
    }

    componentDidMount() {
        const {duration, delay} = this.props;
        const { current } = this.dom;

        if(current){
            this.io = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        current.style.transitionProperty = 'all';
                        current.style.transitionDuration = `${duration}s`;
                        current.style.transitionTimingFunction = 'cubic-bezier(0, 0, 0.2, 1)';
                        current.style.transitionDelay = `${delay}s`;
                        current.style.opacity = 1;
                        current.style.transform = 'translate3d(0, 0, 0)';
                    }
                })
            }, {
                threshold: 0.1
            })
            this.io.observe(current)
        }

    }

    componentWillUnmount() {
        this.io && this.io.disconnect();
    }

    handleDirection = (name) => {
        switch (name) {
            case 'up':
                return 'translate3d(0, 50%, 0)';
            case 'down':
                return 'translate3d(0, -50%, 0)';
            case 'left':
                return 'translate3d(50%, 0, 0)';
            case 'right':
                return 'translate3d(-50%, 0, 0)';
            default:
                return;
        }
    };
    render() {
        return (
            <div ref={this.dom} style={{width: '100%', opacity: 0,
                transform: this.handleDirection(this.props.direction)}}
                 {...this.props}
            >
                {this.props.children}
            </div>
        );
    }
}

export default ScrollFadeIn;