import React, {Component} from 'react';
import {makeObservable, observable} from "mobx";
import Styles from "./BusinessView.module.scss"
import {Col, Container, Image} from "react-bootstrap";
import ScrollFadeIn from "../component/animate/ScrollFadeIn";
import {observer} from "mobx-react";
import IconTab from "../component/tab/IconTab";
import * as IconTabs from "../component/tab/IconTabs";
import {iconTabStore} from "../component/tab/IconTabStore";
const brandLogoRendering = () => {
    const labels =  ["백조싱크", " 깜:포르테", "콰이어트", "블라코", "에이펠", "엘레시"];
    const result = [];
    for(let i=0; i<labels.length; i++){
        result.push(
            <div key={i} className={Styles.Logo}>
                <Image className={Styles.Image} src={`/asset/img/business/sink/BrandLogo${i+1}.svg`}/>
                <div className={Styles.Label}>{labels[i]}</div>
            </div>
        )
    }
    return result;
}

const builtInModuleRendering = (start, end) => {
    const result = [];
    for(let i = start; i <= end; i++) {
        result.push(
            <Image key={i} className={Styles.Item} src={`/asset/img/business/builtIn/dress_module_type${i}.jpg`}/>
        )
    }
    return result;
}
const content = {
    sink: {
        title: `SINK`,
        content: `싱크대, 그 이상의 가치를 만들고 있습니다.`,
        headerImage: `/asset/img/hardware/bowl/header_menu_sinkbowl.png`,
        mainImage: `/asset/img/business/sink/company_info_sink_01.jpg`,
        data: [
            {
                image: `/asset/img/business/sink/company_info_sink_02.jpg`,
                subject: `친환경 E0 도어 부터\n고급 프라임 도장도어까지`,
                content: `사랑하는 우리 아이들과 애완동물을 위해 위험한 포름알데히드 본드를 사용하지 않는 'E0 등급의 목재'로 만든 친환경도어 부터 고급스러운 프라임 도장 도어까지 다양한 색상과 디자인을 만나보세요.`
            },
            {
                image: `/asset/img/business/sink/company_info_sink_03.jpg`,
                subject: `다양한 싱크상판`,
                content: `가정집 주방에서 가장 많이 사용되는 인조 대리석, 화학적 성분 없는 천연 세라믹, 고급스러움과 위생모두 뛰어난 칸스톤 상판 모두 사용 가능 합니다.`
            },
            {
                image: `/asset/img/business/sink/company_info_sink_04.jpg`,
                subject: `나사하나 까지 싱크대를 위한 모든 것`,
                content: `작은 부품하나 놓치지 않습니다. 보이지 않아 놓치지 쉬운 경첩&레일 마지막 디테일 작업의 손잡이까지 원하는건 모두 보유하고 있습니다. `
            }
        ],
        sections : [
            <>
                <ScrollFadeIn
                    direction={'up'}
                    duration={0.5}
                    delay={0.1}
                >
                    <div className={`${Styles.SectionText} ${Styles.StartAlign}`}>
                        <div className={Styles.Subject}>형제 씽크와 함께 합니다.</div>
                        <div className={Styles.Content}>(주)형제씽크 파트너 기업&브랜드</div>
                    </div>
                </ScrollFadeIn>
                <ScrollFadeIn
                    direction={'up'}
                    duration={0.5}
                    delay={0.1}
                >
                    <div className={Styles.BrandLogo}>
                        {brandLogoRendering()}
                    </div>
                </ScrollFadeIn>
            </>,
            <>
                <ScrollFadeIn
                    direction={'up'}
                    duration={0.5}
                    delay={0.1}
                >
                    <div className={`${Styles.SectionText} ${Styles.StartAlign}`}>
                        <div className={Styles.Subject}>자재 & 하드웨어</div>
                        <div
                            className={Styles.Content}>{`친환경 자재부터 트렌디한 디자인을 위한,\n많은 사랑을 받은 싱크대 자재와 하드웨어를 만나보세요.`}</div>
                    </div>
                </ScrollFadeIn>
                <ScrollFadeIn
                    direction={'up'}
                    duration={0.5}
                    delay={0.1}
                >
                    <div className={Styles.TabItem}>
                        <div className={Styles.TabTitle}>자재</div>
                        <div className={Styles.TabIcons}>
                            <IconTab items={IconTabs.materialIcons} isActive={true}/>
                        </div>
                    </div>
                    <div className={Styles.TabItem}>
                        <div className={Styles.TabTitle}>하드웨어</div>
                        <div className={Styles.TabIcons}>
                            <IconTab items={IconTabs.hardwareIcons} isActive={true}/>
                        </div>
                    </div>
                </ScrollFadeIn>
            </>,

        ]

    },
    builtIn: {
        title: `Built-In`,
        content: `평생 사랑하게 될 세상에서 가장 아름다운 공간`,
        headerImage: `/asset/img/home/content/main_image_03.png`,
        mainImage: `/asset/img/business/builtIn/company_info_builtin_01.jpg`,
        data: [
            {
                image: `/asset/img/business/builtIn/company_info_builtin_02.jpg`,
                subject: `친환경 E0 도어 부터\n고급 프라임 도장도어까지`,
                content: `사랑하는 우리 아이들과 애완동물을 위해 위험한 포름알데히드 본드를 사용하지 않는 'E0 등급의 목재'로 만든 친환경도어 부터 고급스러운 프라임 도장 도어까지 다양한 색상과 디자인을 만나보세요.`
            },
            {
                image: `/asset/img/business/builtIn/company_info_builtin_03.jpg`,
                subject: `자유롭게 제작 가능한 맞춤 가구`,
                content: `언제나 고객이 원하는 구성으로 자유롭게 변화, 제작이 가능합니다. 오래된 제작 노하우로 어떠한 공간이던 딱 맞는 스타일 추천도 받아보세요.\n신발장, 옷장, 수납공간 등 어떠한 가구라도 제작 가능합니다.`
            }
        ],
        sections : [
            <>
                <ScrollFadeIn
                    direction={'up'}
                    duration={0.5}
                    delay={0.1}
                >
                    <div className={`${Styles.SectionText} ${Styles.CenterAlign}`}>
                        <div className={Styles.Title}>커스텀 가능한 시스템 모듈 내부장</div>
                        <div className={Styles.Subject}>{`다양한 스타일의 기본 모듈부터\n현장의 크기에 맞는 사이즈로 제작 가능한 커스텀 모듈까지`}</div>
                    </div>
                </ScrollFadeIn>
                <ScrollFadeIn
                    direction={'up'}
                    duration={0.5}
                    delay={0.1}
                >
                    <div className={Styles.BuiltinModule}>
                        {builtInModuleRendering(1,12)}
                    </div>
                </ScrollFadeIn>
            </>,
            <>
                <ScrollFadeIn
                    direction={'up'}
                    duration={0.5}
                    delay={0.1}
                >
                    <div className={`${Styles.SectionText} ${Styles.StartAlign}`}>
                        <div className={Styles.Subject}>자재 & 하드웨어</div>
                        <div
                            className={Styles.Content}>{`친환경 자재부터 트렌디한 디자인을 위한,\n많은 사랑을 받은 싱크대 자재와 하드웨어를 만나보세요.`}</div>
                    </div>
                </ScrollFadeIn>
                <ScrollFadeIn
                    direction={'up'}
                    duration={0.5}
                    delay={0.1}
                >
                    <div className={Styles.TabItem}>
                        <div className={Styles.TabTitle}>자재</div>
                        <div className={Styles.TabIcons}>
                            <IconTab items={IconTabs.materialIcons.filter(icon => icon.key !== 'countertop')} isActive={true}/>
                        </div>
                    </div>
                </ScrollFadeIn>
            </>
        ]
    }
}

const BusinessRender = (props) => {
    const {type} = props;
    return (
        <>
            <div className={Styles.HeaderContent}>
                <div className={`flex-grow-1 p-0 d-flex flex-wrap`}>
                    <Col sm={12} md={6} className={`p-0 align-items-center`}>
                        <div className={Styles.Content}>
                            <div className={`${Styles.LightTitle} `}>Business Introduction</div>
                            <div className={`${Styles.LightTitleBold} `}>사업소개
                                / {content[type].title}</div>
                            <div className={`${Styles.Description} `}>(주)형제씽크의 가치와 방향을 소개합니다.</div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} className={`p-0 d-flex justify-content-end align-items-center`}>
                        <Image className={Styles.Image}
                               src={content[type].headerImage}/>
                    </Col>
                </div>
            </div>
            <div className={Styles.Section}>
                <Container className={Styles.Container}>
                    <ScrollFadeIn
                        direction={'up'}
                        duration={0.5}
                        delay={0.1}
                    >
                        <div className={Styles.Content}>
                            <div className={Styles.Title}>
                                {content[type].title}
                            </div>
                            <div className={Styles.Description}>
                                {content[type].content}
                            </div>
                        </div>
                    </ScrollFadeIn>
                    <ScrollFadeIn
                        direction={'up'}
                        duration={0.5}
                        delay={0.1}
                    >
                        <Image className={'w-100'}
                               src={content[type].mainImage}/>
                    </ScrollFadeIn>
                    {
                        content[type].data.map((data, index) => {
                            return (
                                <ScrollFadeIn
                                    key={index}
                                    direction={'up'}
                                    duration={0.5}
                                    delay={0.1}
                                >
                                    <div className={index % 2 ? Styles.Even : Styles.Odd}>
                                        <div className={Styles.Image}>
                                            <Image className={'w-100'} src={data.image}/>
                                        </div>
                                        <div className={Styles.Text}>
                                            <div className={Styles.Subject}>{data.subject}</div>
                                            <div className={Styles.Content}>{data.content}</div>
                                        </div>
                                    </div>
                                </ScrollFadeIn>
                            )
                        })
                    }
                </Container>
            </div>
            {
                content[type].sections.map((section, index) => {
                    return (
                        <div key={index} className={`${Styles.Section} ${!(index % 2) && Styles.Gray}`}>
                            <Container className={Styles.Container}>
                                {section}
                            </Container>
                        </div>
                    )
                })
            }
        </>
    )
}

@observer
class BusinessView extends Component {

    constructor(props) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        let {match: {params}} = this.props;
        if(params && params.type){
            iconTabStore.selectedTab = params.type;
        }else{
            iconTabStore.selectedTab = "sink";
        }
    }

    render() {
        return (
            <div className={'d-flex flex-column align-items-center'}>
                {iconTabStore.selectedTab === 'sink' && <BusinessRender type={iconTabStore.selectedTab} />}
                {iconTabStore.selectedTab === 'builtIn' && <BusinessRender type={iconTabStore.selectedTab} />}
            </div>
        );
    }
}

export default BusinessView;